.breadcrumb {
  border: none;
  background: none;

  & > li:after {
    top: 5px;
    right: -5px;
    width: 8px;
    height: 8px;
    border-right: 2px solid $primary-color;
    border-bottom: 2px solid $primary-color;
  }
  & > li.last:after {
    display: none;
  }
}

.category .breadcrumb {
  margin: 0px;
}