.page_content {
  margin: 30px auto 50px auto;
}

.product-thumb .button-group button {
  background-color: $alternative-color;
  color: #ffffff;
  &:hover {
    background: rgba(45, 129, 190, 0.9);
    color: #ffffff;
  }
}
.swiper-viewport {
  margin-top: 25px !important;
}

.product-thumb .image, .thumbnails .image {
  position: relative;
  z-index: 1;
  overflow: hidden;
  .label-outofstock {
    position: absolute;
    z-index: 99;
    text-align: center;
    display: block;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
    pointer-events: none;
    top: 0;
    min-width: 200px;
    -webkit-transform: translate(-28%, 100%) rotate(-45deg);
    transform: translate(-28%, 100%) rotate(-45deg);
    -webkit-transform-origin: 53%;
    transform-origin: 53%;
    font-size: 15px;
    padding: .3em 4em .4em;
    white-space: nowrap;
    background: #f56e01;
    color: white;
    line-height: 1;
  }
}

.product-thumb .caption {
  min-height: auto;
  padding: 0 20px 20px;
}


