.page_header {
  background: #002857;
}

.page_header__top {
  &#top {
    background: #002857;
    border: 0px;
    margin: 0px;
    padding: 0px;
    height: 43px;
    border-bottom: 1px solid #05366f;
    .btn-link {
      color: #ffffff;
      text-shadow: none;
      &:hover {
        color: $link-alternative-color;
      }
    }
    .dropdown-menu {
      .btn-link {
        color: $link-hover-color;
      }
    }

    #form-currency .currency-select:hover,
    #form-language .language-select:hover,
    .dropdown-menu li > a:hover {
      background: $link-alternative-color;
      color: $link-hover-color;
    }
  }

  #top-links li,
  #top-links a {
    @extend .btn-link;
  }
}

.page_header__middle {
  margin: 25px auto 20px auto;
  #logo {
    width: 100%;
  }
  #search {
    margin: 0px;
    width: 100%;
    input {
      border: none;
      border-radius: 5px 0 0 5px;
    }
    .btn-lg {
      background: #fff;
      border: none;
      line-height: 20px;
      color: #cccccc;
      padding: 10px 20px;
      .fa{
        font-size: 18px;
      }
    }
  }
  #cart {
    margin: 0;
    & > .btn {
      background: none;
      border: none;
      text-shadow: none;
      box-shadow: none;
      margin: 2px 0;
      float: right;
      text-align: right;
      padding-right: 4px;
      &:hover{
        background: $link-alternative-color;
        color: $link-hover-color;
      }
    }
   &.open > .btn,
   &.open > .btn:hover {
     background: $link-alternative-color;
     color: $link-hover-color;
   }
  }
}

.page_header__bottom {
  background: $alternative-color;
  #menu.navbar {
    margin-bottom: 0px;
    background: none;
    border: none;
    .navbar-collapse {
      padding-left: 0px;
      padding-right: 0px;
    }
    .btn-navbar {
      background: $primary-color;
      border: none;
      &:hover {
        background: $secondary-color;
      }
    }
  }
}

.page_header__top_languag .btn-group > .btn,
.page_header__top_currency .btn-group > .btn {
  padding: 0px 5px;
}
.page_header__top_language {
  margin-right: 30px;
}

@media (min-width: $screen-sm) {
  .page_header__bottom .navbar-collapse.collapse {
    height: 40px !important;
    text-align: left;
    ul.nav {
      float: none;
      display: inline-block;
    }
  }
}
