.row-centered {
  text-align:center;
}
.col-centered {
  display:inline-block;
  float:none;
  /* reset the text-align */
  text-align:left;
  /* inline-block space fix */
  margin-right:-4px;
}

a {
  color: $link-color;
  &:hover {
    color: $link-hover-color;
  }
}

.btn-primary {
  border: none;
  background: $alternative-color;
  color: #ffffff;
  &:hover,
  &:active,
  &:focus,
  &:active:focus,
  &.disabled {
    background: rgba(45, 129, 190, 0.9);
    color: #ffffff;
  }
}

.btn-default {
  color: #FFF;
  text-shadow: none;
  background: $primary-color;
  border-top: 1px solid #222;
  &:hover {
    background: $secondary-color;
    color: #FFF;
  }
}