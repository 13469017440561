footer.page_footer {
  background: $primary-color;
  h3 {
    color: $primary-color;
  }
}

.page_footer__copyright {
  padding: 30px;
  text-align: right;
}


.cookies-warning {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #0082BB;
  display: none;
  z-index: 1000;
  padding: 2% 5% 1%;
  text-align: center;
  color: #fff;
  .cookies-policy {
    display: inline-block;
    max-width: 1050px;
  }
  .btn-default {
    padding: .6em 1.2em;
    font-size: 1.1em;
    font-weight: 500;
    margin-left: 60px;
    margin-top: -30px;
    border: none;
    background: #002857;
    color: #ffffff;
    &:hover:active:focus {
      background: #002857;
      opacity: 0.9;
    }
  }
}

@media (max-width: 1333px) {
  .cookies-warning {
    text-align: center;
    p {
      text-align: center;
    }
    .cookies-policy {
      display: block;
    }
    .btn-default {
      margin: 0 auto;
    }
  }
}